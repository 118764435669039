<template>
  <div id="home">

    <div class="div">
      <div class="title">
        Bills
        <div>
          Invoice
          <el-select placeholder="请选择">
            <el-option
              v-for="item,index in 5"
              :key="index">2342342434
            </el-option>
          </el-select>
        </div>
        <div>
          Due
          <span class="span">Mar 16, 2024</span>
        </div>
        <span class="btn">Download</span>
      </div>
      <div class="payment">
        Amount
        <div class="conp">
          $2,400
          <div class="record">
            <p>
              SEO Service
              <b>$1,920</b>
            </p>
            <p>
              Social Media Service
              <b>$820</b>
            </p>
            <p>
              Credit card fee(3.3%)
              <b>$12</b>
            </p>
            <p>
              Total
              <b>$2,400</b>
            </p>
          </div>
        </div>
      </div>

      <div class="torbar">
        <div class="act">
          Payment Methods
        </div>
        <div>
        </div>
      </div>


      <div>
        <a class="addBtn">
          Add Payment Method
        </a>
      </div>

      <div class="title">
        <div></div>
        <span class="btn">Pay</span>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  export default {
    name: 'PaymentHistory',
    components: {
    },
    data() {
      return {
        paymentIndex:0
      }
    },
    computed: {

    },
    mounted() {

    },
    created(){

    },
    methods:{

    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;flex-direction: column;font-size: 16px;}
  .title div .span{color:#5856D6;font-size: 16px;}

  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .div{padding:20px 5px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
