<template>
  <el-dialog title="Add Payment" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="visible = false">
    <div class="form" v-loading="form_loading">
      <div class="flex">
        <b>Credit or Debit<span>*</span></b>
        <img src="../../assets/imgs/paymentCard.jpg" style="height: 26px;"/>
      </div>
      <div class="border">
        <el-input placeholder="Card Number" v-model="info.credit_card_number" style="width: 100%;"></el-input>
        <el-date-picker
          style="margin:0 10px;width: 130px;flex-shrink: 0;"
          v-model="mon"
          type="month"
          :editable="false"
          :clearable="false"
          format="MM/yy"
          value-format="M/yyyy"
          placement="bottom-start"
          placeholder="MM/YY">
        </el-date-picker>
        <el-input placeholder="CVV" v-model="info.credit_card_cvv" style="width: 150px;text-align: center;"></el-input>
      </div>
      <div class="grid">
        <div class="row">
          <b>First Name<span>*</span></b>
          <el-input placeholder="First Name" v-model="info.first_name"></el-input>
        </div>
        <div class="row">
          <b>Last Name<span>*</span></b>
          <el-input placeholder="Last Name" v-model="info.last_name"></el-input>
        </div>
        <div class="row">
          <b>Phone<span>*</span></b>
          <el-input v-model="info.phone_number" placeholder="Phone" @change="change_phone"></el-input>
        </div>
        <div class="row">
          <b>Billing Address<span>*</span></b>
          <el-input placeholder="Billing Address" v-model="info.address1"></el-input>
        </div>
        <div class="row">
          <b>Address Line2</b>
          <el-input placeholder="Address Line2" v-model="info.address2"></el-input>
        </div>
        <div class="row">
          <b>Bill Postal/Zip Code<span>*</span></b>
          <el-input placeholder="Bill Postal/Zip Code" v-model="info.zip_code"></el-input>
        </div>
        <div class="row">
          <b>City<span>*</span></b>
          <el-input placeholder="City" v-model="info.city"></el-input>
        </div>
        <div class="row">
          <b>Country<span>*</span></b>
          <el-select v-model="info.country" style="width: 100%;" placeholder="state">
            <el-option
              v-for="item,index in countryList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="row">
          <b>State</b>
          <el-select v-model="info.state" style="width: 100%;" placeholder="state">
            <el-option
              v-for="item,index in stateList"
              :key="index"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="align-self: flex-end;">
        <span class="btn" @click="submitInfo">Save</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import { getActionApi, postActionApi } from '@/network/manageApi';
import moment from 'moment/moment';
export default {
  name: 'Add',
  components: {},
  data() {
    return {
      dialogWidth:'800px',
      visible: false,
      mon:null,
      info: {},
      countryList:['UNITED_STATES', 'CANADA', 'MEXICO', 'BRAZIL', 'ARGENTINA', 'UNITED_KINGDOM', 'FRANCE', 'GERMANY', 'ITALY', 'SPAIN', 'RUSSIA', 'CHINA', 'INDIA', 'JAPAN', 'AUSTRALIA', 'SOUTH_AFRICA', 'EGYPT', 'NIGERIA', 'SAUDI_ARABIA', 'TURKEY', 'SOUTH_KOREA', 'INDONESIA', 'PAKISTAN', 'BANGLADESH', 'VIETNAM', 'THAILAND', 'MALAYSIA', 'PHILIPPINES', 'SINGAPORE', 'NEW_ZEALAND', 'NORWAY', 'SWEDEN', 'DENMARK', 'FINLAND', 'POLAND', 'UKRAINE', 'NETHERLANDS', 'BELGIUM', 'SWITZERLAND', 'AUSTRIA', 'GREECE', 'PORTUGAL', 'CZECH_REPUBLIC', 'HUNGARY', 'ROMANIA', 'SLOVAKIA', 'BULGARIA', 'IRELAND', 'ICELAND', 'ISRAEL', 'IRAN', 'IRAQ', 'AFGHANISTAN', 'SYRIA', 'LEBANON', 'JORDAN', 'KUWAIT', 'QATAR', 'UNITED_ARAB_EMIRATES', 'OMAN', 'YEMEN', 'ETHIOPIA', 'KENYA', 'TANZANIA', 'UGANDA', 'ZIMBABWE', 'GHANA', 'ALGERIA', 'MOROCCO', 'TUNISIA', 'LIBYA', 'SUDAN', 'CHILE', 'PERU', 'COLOMBIA', 'VENEZUELA', 'ECUADOR', 'PARAGUAY', 'URUGUAY', 'BOLIVIA', 'PANAMA', 'COSTA_RICA', 'CUBA', 'HONDURAS', 'EL_SALVADOR', 'GUATEMALA', 'NICARAGUA', 'DOMINICAN_REPUBLIC', 'HAITI', 'JAMAICA', 'TRINIDAD_AND_TOBAGO'],
      stateList:['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY'],
      form_loading: false
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    moment,
    init() {
      this.visible = true
      this.mon = null
      this.form_loading = false
      this.info = {}
    },
    change_phone() {
      if(!this.info.phone_number) {
        return;
      }
      let phone = this.info.phone_number.replaceAll("-", "").replace(/[^0-9]/g, '')
      if(phone.length != 10) {
        this.$message({
          message: 'Please enter the correct phone number.',
          type: 'warning'
        });
        this.info.phone_number = ""
        return;
      }
      phone = "+1" + phone
      this.info.phone_number = "+1-" + phone.substring(2,5) + "-" + phone.substring(5,8) + "-" + phone.substring(8);
    },
    submitInfo(){
      if(!this.info.credit_card_number){
        this.$message.error('Credit or Debit Required!');
      }else if(!this.mon){
        this.$message.error('Credit or Debit Required!');
      }else if(!this.info.credit_card_cvv){
        this.$message.error('Credit or Debit Required!');
      }else if(!this.info.first_name){
        this.$message.error('First Name Required!');
      }else if(!this.info.last_name){
        this.$message.error('Last Name Required!');
      }else if(!this.info.phone_number){
        this.$message.error('Phone Required!');
      }else if(!this.info.address1){
        this.$message.error('Billing Address Required!');
      }else if(!this.info.zip_code){
        this.$message.error('Bill Postal/Zip Code Required!');
      }else if(!this.info.city){
        this.$message.error('City Required!');
      }else if(!this.info.country){
        this.$message.error('Country Required!');
      }else{
        if(!this.validateNumber())
          this.$message.error('UNKNOWN Card Number!');
        else{
          this.form_loading = true
          this.info.credit_card_expired_month = parseInt(this.mon.substring(0,this.mon.indexOf('/')))
          this.info.credit_card_expired_year = parseInt(this.mon.substring(this.mon.indexOf('/') + 1))
          this.info.default_payment = true
          this.info.payment_methods = 'CREDIT_CARD'
          postActionApi('/payment/payment/methods',this.info).then((res) => {
            this.visible = false
            this.$emit('ok')
          }).catch((err) => {
            this.$message.error(err.response.data.details.data.message);
          }).finally((res) => {
            this.form_loading = false
          })
        }
      }
    },
    validateNumber(){
      if(this.info.credit_card_number.indexOf('4') == 0)
        return true
      else if(this.info.credit_card_number.indexOf('51') == 0 || this.info.credit_card_number.indexOf('52') == 0 || this.info.credit_card_number.indexOf('53') == 0 || this.info.credit_card_number.indexOf('54') == 0 || this.info.credit_card_number.indexOf('55') == 0)
        return true
      else if(this.info.credit_card_number.indexOf('34') == 0 || this.info.credit_card_number.indexOf('37') == 0)
        return true
      else if(this.info.credit_card_number.indexOf('6011') == 0)
        return true
      else if(this.info.credit_card_number.indexOf('300') == 0 || this.info.credit_card_number.indexOf('301') == 0 || this.info.credit_card_number.indexOf('302') == 0 || this.info.credit_card_number.indexOf('303') == 0 || this.info.credit_card_number.indexOf('304') == 0 || this.info.credit_card_number.indexOf('305') == 0 || this.info.credit_card_number.indexOf('36') == 0 || this.info.credit_card_number.indexOf('38') == 0 || this.info.credit_card_number.indexOf('39') == 0)
        return true
      else if(this.info.credit_card_number.indexOf('3528') == 0 || this.info.credit_card_number.indexOf('3529') == 0 || this.info.credit_card_number.indexOf('353') == 0 || this.info.credit_card_number.indexOf('354') == 0 || this.info.credit_card_number.indexOf('355') == 0 || this.info.credit_card_number.indexOf('356') == 0 || this.info.credit_card_number.indexOf('357') == 0 || this.info.credit_card_number.indexOf('358') == 0)
        return true
      else
        return false
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 800 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  *{word-break:normal;}
  .form{display: flex;flex-direction: column;}
  .form .flex{display: flex;justify-content: space-between;align-items: center;}
  .border{margin:10px 0;display: flex;}
  .grid{display: grid;grid-gap: 20px;grid-template-columns: 1fr 1fr;margin:20px 0;}
  .row{display: flex;flex-direction: column;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  b{font-size: 16px;}
  b span{color:red;margin-left:6px;font-weight: normal;}
  @media (max-width: 768px) {
    .grid{grid-template-columns: 1fr;}
  }
</style>
