<template>
  <div id="home">

    <div class="div">
      <div class="title" v-loading="paymentLoading">
        Bills
        <div v-if="paymentDetail != null">
          Invoice
          <el-select v-model="value" @change="selectChange">
            <el-option
              v-for="item,index in paymentList"
              :key="index"
              :value="item.client_invoice_uuid">
              <div style="font-size: 12px;color:#333333;">{{item.client_invoice_uuid}} <span style="color:red;">Unpaid</span></div>
            </el-option>
          </el-select>
        </div>
        <div v-if="paymentDetail != null">
          Due
          <span class="span">{{getMonStr(moment(paymentItem.due_date).format('M'))}} {{moment(paymentItem.due_date).format('DD,YYYY')}}</span>
        </div>
        <span v-if="paymentDetail != null" class="btn" @click="downloadFunction" v-loading="downloadLoading">Download</span>
      </div>
      <div v-if="paymentDetail != null">
        <div class="payment" v-loading="paymentDetailLoading">
          Amount
          <div class="conp">
            ${{paymentDetail.balance_amounts}}
            <div class="record">
              <p v-for="item,index in paymentDetail.items" :key="index">
                {{item.item_description}}
                <b>${{item.amounts}}</b>
              </p>
              <p>
                Total
                <b>${{paymentDetail.balance_amounts}}</b>
              </p>
            </div>
          </div>
        </div>

        <div class="torbar">
          <div class="act">
            Payment Methods
          </div>
          <div>
          </div>
        </div>

        <div class="paymentDiv" v-loading="paymentMethodLoading">
          <div class="item" @click="paymentIndex = index" v-for="item,index in paymentMethods" :key="index">
            <div class="info">
              <div class="cadInfo">
                <img class="img" v-if="getCardImg(item.credit_card_number) == 'VISA'" src="../assets/imgs/VISA.png"/>
                <img class="img" v-if="getCardImg(item.credit_card_number) == 'MASTERCARD'" src="../assets/imgs/MASTERCARD.png"/>
                <img class="img" v-if="getCardImg(item.credit_card_number) == 'AMERICAN_EXPRESS'" src="../assets/imgs/AMERICAN_EXPRESS.png"/>
                <img class="img" v-if="getCardImg(item.credit_card_number) == 'DISCOVER'" src="../assets/imgs/DISCOVER.jpg"/>
                <img class="img" v-if="getCardImg(item.credit_card_number) == 'DINERS_CLUB'" src="../assets/imgs/DINERS_CLUB.jpg"/>
                <img class="img" v-if="getCardImg(item.credit_card_number) == 'JCB'" src="../assets/imgs/JCB.jpg"/>
                <div>
                  {{getCardNum(item.credit_card_number)}}
                  <span>Expires {{item.credit_card_expired_month < 10 ? '0' : ''}}{{item.credit_card_expired_month}}/{{item.credit_card_expired_year}}</span>
                </div>
              </div>
              <input type="radio" :checked="paymentIndex == index"/>
            </div>
            <div class="options">
              <b v-if="item.credit_card_default">Default</b>
              <b v-else style="color:#5856D6;" @click.stop="setDefault(item)">Set as default</b>
              <a class="delete" @click.stop="deletePaymentMethod(item.client_payment_methods)">
                Delete
              </a>
            </div>

          </div>
        </div>
        <div>
          <a class="addBtn" @click="show_add">
            Add Payment Method
          </a>
        </div>

        <div class="title">
          <div></div>
          <span class="btn">Pay</span>
        </div>
      </div>
      <add ref="addForm" @ok="loadPaymentMethods"></add>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { deleteActionApi, getActionApi,downFileApi, putActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  import Add from './bills/add'
  export default {
    name: 'Bills',
    components: {
      Add
    },
    data() {
      return {
        paymentIndex:0,
        paymentList:[],
        value:'',
        paymentItem:{},
        paymentDetail:null,
        paymentMethods:[],
        downloadLoading:false,
        paymentLoading:false,
        paymentDetailLoading:false,
        paymentMethodLoading:false
      }
    },
    computed: {

    },
    mounted() {

    },
    created(){
      this.loadPayment()
      this.loadPaymentMethods()
    },
    methods:{
      moment,
      getCardNum(num){
        let newNum = ''
        for(let i = 0;i<num.length - 4;i++)
          newNum += '*'
        return newNum + num.substring(num.length - 4)
      },
      getMonStr(mon){
        const monStr = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        return monStr[mon-1]
      },
      downloadFunction(){
        this.downloadLoading = true
        downFileApi('/payment/invoices/'+this.paymentDetail.client_invoice_uuid+'/download').then(res => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download','invoices.pdf')
          document.body.appendChild(link)
          link.click()
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.downloadLoading = false
        })
      },
      loadPayment(){
        this.paymentLoading = true
        getActionApi('/payment/invoices/unpaid').then(res => {
          this.paymentList = res.data
          if(res.data && res.data.length > 0){
            this.value = res.data[0].client_invoice_uuid
            this.selectChange()
          }
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.paymentLoading = false
        })
      },
      deletePaymentMethod(id){
        const that = this
        this.$confirm('Are you sure to delete?', 'warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          that.paymentMethodLoading = true
          deleteActionApi('/payment/payment/methods/'+id).then((res) => {
            that.loadPaymentMethods()
          }).catch((err) => {
            that.$message.error(err.response.data.details.data.message);
          }).finally((res) => {
            that.paymentMethodLoading = false
          })
        }).catch(() => {});
      },
      loadPaymentMethods(){
        this.paymentMethodLoading = true
        getActionApi('/payment/payment/methods').then(res => {
          this.paymentMethods = res.data
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.paymentMethodLoading = false
        })
      },
      show_add() {
        this.$refs.addForm.init()
      },
      setDefault(item){
        this.paymentMethodLoading = true
        let params = {
          default_payment:true,
          credit_card_expired_year:item.credit_card_expired_year,
          credit_card_expired_month:item.credit_card_expired_month,
          credit_card_cvv:item.credit_card_cvv,
          first_name:item.first_name,
          last_name:item.last_name,
          address1:item.address1,
          city:item.city,
          country:item.country,
          phone_number:item.phone_number,
          zip_code:item.zip_code
        }
        if(item.address2 != null)
          params.address2 = item.address2
        if(item.state != null)
          params.state = item.state
        putActionApi('/payment/payment/methods/'+item.client_payment_methods,params).then((res) => {
          this.loadPaymentMethods()
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.paymentMethodLoading = false
        })
      },
      selectChange(){
        for(let i = 0; i < this.paymentList.length; i++){
          if(this.paymentList[i].client_invoice_uuid == this.value){
            this.paymentItem = this.paymentList[i]
          }
        }
        this.paymentDetailLoading = true
        getActionApi('/payment/invoices/' + this.value).then(res => {
          this.paymentDetail = res.data
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.paymentDetailLoading = false
        })
      },
      getCardImg(cardNumber){
        if(cardNumber.indexOf('4') == 0)
          return 'VISA'
        else if(cardNumber.indexOf('51') == 0 || cardNumber.indexOf('52') == 0 || cardNumber.indexOf('53') == 0 || cardNumber.indexOf('54') == 0 || cardNumber.indexOf('55') == 0)
          return 'MASTERCARD'
        else if(cardNumber.indexOf('34') == 0 || cardNumber.indexOf('37') == 0)
          return 'AMERICAN_EXPRESS'
        else if(cardNumber.indexOf('6011') == 0)
          return 'DISCOVER'
        else if(cardNumber.indexOf('300') == 0 || cardNumber.indexOf('301') == 0 || cardNumber.indexOf('302') == 0 || cardNumber.indexOf('303') == 0 || cardNumber.indexOf('304') == 0 || cardNumber.indexOf('305') == 0 || cardNumber.indexOf('36') == 0 || cardNumber.indexOf('38') == 0 || cardNumber.indexOf('39') == 0)
          return 'DINERS_CLUB'
        else if(cardNumber.indexOf('3528') == 0 || cardNumber.indexOf('3529') == 0 || cardNumber.indexOf('353') == 0 || cardNumber.indexOf('354') == 0 || cardNumber.indexOf('355') == 0 || cardNumber.indexOf('356') == 0 || cardNumber.indexOf('357') == 0 || cardNumber.indexOf('358') == 0)
          return 'JCB'
        else
          return ''
      },
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;flex-direction: column;font-size: 16px;}
  .title div .span{color:#5856D6;font-size: 16px;}
  .torbar{display: flex;align-items: center;}
  .torbar div{width: 100%;display: flex;align-items: center;justify-content: center;height: 40px;font-size:14px;border:1px solid transparent;border-bottom:1px solid #CBD5E0;}
  .torbar .act{background-color: #ffffff;border-radius: 6px 6px 0 0;border: 1px solid #CBD5E0;border-bottom-color:#ffffff;color:#5856D6;}
  .torbar div:first-child{width: 240px;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  .paymentDiv{display: grid;grid-template-columns:repeat(auto-fit,300px);grid-gap: 20px;margin:30px 0;}
  .paymentDiv .item{box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);padding:20px;border-radius: 10px;cursor: pointer;display:flex;align-items: flex-start;flex-direction: column;}
  .paymentDiv .item .info{width: 100%;display: flex;align-items: flex-start;}
  .paymentDiv .item .info .cadInfo{width: 100%;}
  .paymentDiv .item .delete{color:red;font-size: 14px;cursor: pointer;}
  .cadInfo{display: flex;align-items: center;margin-bottom: 10px;}
  .cadInfo .img{width: 50px;height: 30px;margin-right: 10px;object-fit: contain;}
  .cadInfo div{display: flex;flex-direction: column;font-size: 12px;line-height: 1;font-size: 14px;}
  .cadInfo div span{margin-top:4px;font-size:12px;color: #999999;}
  .paymentDiv .item input{width: 20px;height:20px;}
  .paymentDiv .options{display: flex;justify-content: space-between;align-items: center;width: 100%;font-size: 14px;color:gray;}
  .addBtn{cursor: pointer;background-color: #f7f7f7;font-size: 12px;color: #666666;border-radius: 4px;padding:6px 16px;}
  .payment{font-size: 20px;font-weight: bold;display: flex;flex-direction: column;}
  .payment .conp{padding:10px 20px;font-size: 20px;font-weight: bold;display: flex;flex-direction: column;}
  .payment .conp .record{padding:10px 20px;font-size: 14px;font-weight: normal;width: 500px;display: grid;grid-row-gap: 6px;}
  .payment .conp .record p{display: flex;justify-content: space-between;}
  .payment .conp .record p b{font-size: 16px;}
  .payment .conp .record p:last-child{border-top:1px solid #f7f7f7;padding-top:10px;}
  .payment .conp .record p:last-child b{font-size: 20px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;margin:0 20px;}
    .title div{width: 100%;line-height: 2;}
    .div{padding:20px 5px;}
    .payment .conp .record{width: 100%;}
    .paymentDiv{grid-template-columns:1fr;margin:20px;}
    .torbar{margin:0 20px;}
    .payment{margin:0 20px;}
    .payment .conp{padding:10px 0;}
    .payment .conp .record{padding:10px 0;}
    .btn{align-self: flex-end;}
    .addBtn{margin-left:20px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
