<template>
  <div id="register">

    <form onsubmit="return false;">
    <!--      Make your own form or copy this one -->
        <div class="group">
          <label>
            <span>First Name</span>
            <input name="cardholder-first-name" class="field input-box" placeholder="Jane" />
          </label>
          <label>
            <span>Last Name</span>
            <input name="cardholder-last-name" class="field input-box" placeholder="Doe" />
          </label>
          <label>
            <span>Phone</span>
            <input name="phone" class="field input-box"  placeholder="+1000000000000" />
          </label>
        </div>
        <div class="group">
          <label>
            <span>Card</span>
            <div id="card-element" class="field">
              <div id="staxjs-number" style="width:180px; height:35px; display: inline-block; margin:3px"></div>
              <div id="staxjs-cvv" style="width:50px; height:35px; display: inline-block; margin:3px"></div>
            </div>
            <div style="width:40px; height:35px; display: inline-block;">
              <input name="month" size="3" maxlength="2" placeholder="MM" style="width: 22px; height:18px; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%;">
            </div>
            /
            <div style="width:55px; height:35px; display: inline-block;padding: 0 8px 0 0">
              <input name="year" size="5" maxlength="4" placeholder="YYYY" style="width: 40px; height:18px; border-radius: 3px; border: 1px solid #ccc; padding: .5em .5em; font-size: 91%">
            </div>
          </label>
        </div>
        <button id="paybutton">Pay $1</button>
        <button id="tokenizebutton">Tokenize Card</button>
        <!-- <button id="verifybutton">verify $1</button> -->
        <div class="outcome">
          <div class="error"></div>
          <div class="success">
            Successful! The ID is
            <span class="token"></span>
          </div>
          <div class="loader" style="margin: auto">
          </div>
        </div>
      </form>


  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import Vue from 'vue'
  import CryptoJS from 'crypto-js'



  export default {
    name: 'FBLogin',
    components: {
    },
    data() {
      return {
        cameraStream: null,
        flip_over_flag: true,
        secretKey: "EvK9GiYp18vkmDL0XGF0zkmly0U6jFiB20yaxorhViFEM5wDGhKf6ZSIClSe5V16",
        data: {"client_invoice_uuid": "string",
        "transaction_uuid": "string",
        "transaction_type": "string",
        "client_payment_methods_uuid":
        "string", "paid_amount": 100,
        "success": true, "message": "string",
        "total": 0, "method": "string",
        "created_at": "2025-01-10T20:52:36",
        "updated_at": "2025-01-10T20:52:36"},
        target_data: '{"client_invoice_uuid": "string", "transaction_uuid": "string", "transaction_type": "string", "client_payment_methods_uuid": "string", "paid_amount": 100, "success": true, "message": "string", "total": 0, "method": "string", "created_at": "2025-01-10T20:52:36", "updated_at": "2025-01-10T20:52:36"}'
      }
    },
    mounted() {
    },
    computed: {

    },
    created() {
      console.log(this.generateHMACSignature());
      this.init_stax_pay()
    },
    methods: {
      generateHMACSignature() {
        let obj = JSON.stringify(this.data)
        console.log(obj);
        console.log(this.target_data);
        const hmac = CryptoJS.HmacSHA256(obj, this.secretKey);        return hmac.toString(CryptoJS.enc.Base64);
      },
      init_stax_pay() {
        var staxjs = new StaxJs( "Ditans-group-a7e1108b439b" ,
        {
          number: {
            id: 'staxjs-number',
            placeholder: '0000 0000 0000 0000',
            style: 'height: 30px; width: 100%; font-size: 15px;'
          },
          cvv: {
            id: 'staxjs-cvv',
            placeholder: 'CVV',
            style: 'height: 30px; width: 100%; font-size: 15px;'
          }
        });
        staxjs.showCardForm().then((handler) => {
          console.log("form was loaded");
        }).catch((err) => {
          console.log("there was an error loading the form: ", err);
        });
      }
    }
  }
</script>

<style scoped>

  * {
    font-family: "Helvetica Neue", Helvetica;
    font-size: 15px;
    font-variant: normal;
    padding: 0;
    margin: 0;
}

html {
    height: 100%;
}

body {
    background: #E6EBF1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

form {
    width: 530px;
    margin: 20px 0;
}

.group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
}

label {
    position: relative;
    color: #8898AA;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
}

.group label:not(:last-child) {
    border-bottom: 1px solid #F0F5FA;
}

label>span {
    width: 80px;
    text-align: right;
    margin-right: 30px;
}

.field {
    background: transparent;
    font-weight: 300;
    color: #31325F;
    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
}

.field::-webkit-input-placeholder {
    color: #CFD7E0;
}

.field::-moz-placeholder {
    color: #CFD7E0;
}

button {
    float: left;
    display: block;
    background:#555ABF;
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    line-height: 38px;
    outline: none;
    cursor: pointer
}

button:focus {
    background: #555ABF;
}

button:active {
    background: #43458B;
}

button[disabled] {
    background:  #8898AA;
    cursor: default
}

.outcome {
    float: left;
    width: 100%;
    padding-top: 8px;
    min-height: 24px;
    text-align: center;
}

.success,
.error,
.loader {
    display: none;
    font-size: 13px;
}

.success.visible,
.error.visible {
    display: inline;
}

.loader.visible {
    display: block;
}

.error {
    color: #E4584C;
}

.success {
    color: #666EE8;
}

.success .token {
    font-weight: 500;
    font-size: 13px;
}


.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #666EE8; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    margin: 8px
}

.loader-small {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #666EE8; /* Blue */
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.input-box {
  margin-left: 12px;
  margin-right: 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
</style>
