<template>
  <el-dialog title="Inquiry Detail" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="visible = false">
    <div class="form" v-loading="form_loading">
      <div class="left">
        <div class="div">
          <h1>Incoming Call Overview</h1>
          <div class="flex">
            <a v-if="info.phone_number_from">({{info.phone_number_from.substring(2,5)}}) {{info.phone_number_from.substring(5,8)}} - {{info.phone_number_from.substring(8)}}</a>
            <a>{{timeDifference(info.call_start_datetime,info.call_duration)}}</a>
          </div>
          <div class="flex border">
            <font>First Inquiry Date/Time</font>
            <b v-if="info.inquiry_datetime">{{moment(info.inquiry_datetime).format('MM/DD/YY, HH:mm:ss')}}</b>
          </div>
          <div class="flex border">
            <font>Category</font>
            <b>{{translate_category(info.category)}}</b>
          </div>
          <div class="flex border">
            <font>Conversion</font>
            <b v-if="info.conversion != null">{{info.conversion?'Yes':'No'}}</b>
          </div>
          <div class="flex border">
            <font>Caller Name</font>
            <b v-if="info.caller_name != null">{{info.caller_name}}</b>
            <b v-else></b>
          </div>
          <div class="flex border">
            <font>Respondent Name</font>
            <b v-if="info.callee_name != null">{{info.callee_name}}</b>
            <b v-else></b>
          </div>
          <div class="flex border">
            <font>New Client</font>
            <b>{{translate_new_client(info.new_client)}}</b>
          </div>
          <div class="flex border">
            <font>Status</font>
            <b>{{translate_status(info.call_status)}}</b>
          </div>
        </div>
        <div class="div">
          <h1>Quality Control</h1>
          <div class="flex border">
            <font>Customer Satisfaction</font>
            <el-rate
              v-model="info.customer_satisfaction" disabled>
            </el-rate>
          </div>
          <div class="flex border">
            <font>Issue Resolved</font>
            <el-rate
              v-model="info.issued_resolved" disabled>
            </el-rate>
          </div>
          <div class="flex border">
            <font>Comment</font>
            <p>{{info.comment}}</p>
          </div>
        </div>
      </div>
      <div class="div">
        <h1>Summary</h1>
        <p>{{info.summary}}</p>
        <h1>Keywords</h1>
        <div class="label">
          <el-tag v-for="item,index in info.key_words" :key="index">{{item}}</el-tag>
        </div>
        <h1>Call Transcription</h1>
        <div :class="showAll?'':'hideComm'">
          <div v-for="(item,index) in call_content_array" :key="index" v-html="item"></div>
        </div>
        <div @click="showAll = false" v-if="showAll" style="font-size: 14px;color: #409EFF;text-decoration: underline;cursor: pointer;">view less</div>
        <div @click="showAll = true" v-if="!showAll" style="font-size: 14px;color: #409EFF;text-decoration: underline;cursor: pointer;">view more</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import { getActionApi } from '@/network/manageApi';
import moment from 'moment/moment';
export default {
  name: 'Show',
  components: {},
  data() {
    return {
      dialogWidth:'1088px',
      value:5,
      showAll:false,
      visible: false,
      info: {},
      form_loading: false,
      call_content_array: [],
      status_map: {"Answered":"Answered", "Missed call":"Missed call",
        "Busy": "Busy", "Unknown":"Unknown"}
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    moment,
    translate_new_client(str){
      if(str == null || str == "" || str == "UNKNOWN") {
        return "";
      } else if(str == "YES") {
        return "Yes"
      } else if(str == "NO") {
        return "No"
      }
    },
    translate_status(status) {
      if(status) {
        return this.status_map[status]
      }
      return;
    },
    translate_category(status) {
      if(status) {
        return status.replaceAll("_", " ")
      }
      return;
    },
    format_call_content(str) {
      let split = str.split("\n")
      let call_content = []
      for(let item of split) {
        if(item != "") {
          let reg_respondent = /respondent\s\(.*\):/gi
          let array_respondent = item.match(reg_respondent)
          if(array_respondent) {
            for(let i=0;i < array_respondent.length; i++) {
              item = item.replaceAll(array_respondent[i], "<span class='yellow_color'>" + array_respondent[i].replaceAll("respondent", "Respondent") + "</span>")
            }
          }

          reg_respondent = /respondent\(.*\):/gi
          array_respondent = item.match(reg_respondent)
          if(array_respondent) {
            for(let i=0;i < array_respondent.length; i++) {
              item = item.replaceAll(array_respondent[i], "<span class='yellow_color'>" + array_respondent[i].replaceAll("respondent", "Respondent") + "</span>")
            }
          }

          let reg_respo = /respondent:/gi
          let array_respo = item.match(reg_respo)
          if(array_respo) {
            for(let i=0;i < array_respo.length; i++) {
              item = item.replaceAll(array_respo[i], "<span class='yellow_color'>" + array_respo[i].replaceAll("respondent", "Respondent") + "</span>")
            }
          }

          let reg = /callee\s\(.*\):/gi
          let array = item.match(reg)
          if(array) {
            for(let i=0;i < array.length; i++) {
              item = item.replaceAll(array[i], "<span class='yellow_color'>" + array[i].replaceAll("callee", "Respondent").replaceAll("Callee", "Respondent") + "</span>")
            }
          }

          reg = /callee\(.*\):/gi
          array = item.match(reg)
          if(array) {
            for(let i=0;i < array.length; i++) {
              item = item.replaceAll(array[i], "<span class='yellow_color'>" + array[i].replaceAll("callee", "Respondent").replaceAll("Callee", "Respondent") + "</span>")
            }
          }

          let reg_call = /callee:/gi
          let array_call = item.match(reg_call)
          if(array_call) {
            for(let i=0;i < array_call.length; i++) {
              item = item.replaceAll(array_call[i], "<span class='yellow_color'>" + array_call[i].replaceAll("callee", "Respondent").replaceAll("Callee", "Respondent") + "</span>")
            }
          }

          let reg_caller_name = /caller\(.*\):/gi
          let array_caller_name = item.match(reg_caller_name)
          if(array_caller_name) {
            for(let i=0;i < array_caller_name.length; i++) {
              item = item.replaceAll(array_caller_name[i], "<span class='green_color'>" + array_caller_name[i].replaceAll("caller", "Caller") + "</span>")
            }
          }

          reg_caller_name = /caller\s\(.*\):/gi
          array_caller_name = item.match(reg_caller_name)
          if(array_caller_name) {
            for(let i=0;i < array_caller_name.length; i++) {
              item = item.replaceAll(array_caller_name[i], "<span class='green_color'>" + array_caller_name[i].replaceAll("caller", "Caller") + "</span>")
            }
          }

          let reg_caller = /caller:/gi
          let array_caller = item.match(reg_caller)
          if(array_caller) {
            for(let i=0;i < array_caller.length; i++) {
              item = item.replaceAll(array_caller[i], "<span class='green_color'>" + array_caller[i].replaceAll("caller", "Caller") + "</span>")
            }
          }
          call_content.push(item)
        }
      }
      return call_content
    },
    init(id) {
      this.visible = true
      this.call_content_array = []
      this.showAll = false
      this.form_loading = true
      this.info = {}
      getActionApi('inquiry/call-inquiry/'+id).then((res) => {
        this.info = res.data
        this.call_content_array = this.format_call_content(this.info.call_transcript)
      }).catch((err) => {

      }).finally((res) => {
        this.form_loading = false
      })
    },
    timeDifference(time1, num) {
      const duration = moment.duration(moment(moment(time1).add('second',num)).diff(moment(time1)));
      let result = '';
      if (duration.days() > 0) {
        result += `${duration.days()} d`;
      }
      if (duration.hours() > 0) {
        if (result) {
          result += ` `;
        }
        result += `${duration.hours()} h`;
      }
      if (duration.minutes() > 0) {
        if (result) {
          result += ` `;
        }
        result += `${duration.minutes()} min`;
      }
      if (duration.seconds() > 0) {
        if (result) {
          result += ` `;
        }
        result += `${duration.seconds()} sec`;
      }
      return result;
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 1088 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  *{word-break:normal;}
  .form{display: grid;grid-template-columns:1fr 1fr;}
  .form .flex{display: flex;justify-content: space-between;min-height:60px;align-items: center;font-size: 14px;}
  .form .flex font{flex-shrink: 0;}
  .border{border-bottom: 1px solid #EDF2F7;}
  .left{border-right: 1px solid #EDF2F7;padding-right:30px;margin-right: 30px;}
  .form a{font-size: 18px;font-weight: bold;color: #5856D6;}
  .hideComm{max-height: 130px;overflow-y: hidden;}
  pre{white-space:pre-wrap;word-wrap:break-word;}
  h1{font-size: 18px;margin:20px 0;}
  b{font-size: 18px;}
  p{font-size: 14px;margin-left:20px;white-space:pre-wrap;word-wrap:break-word;}
  .border p{text-align: right;}
  .label{display:flex;flex-wrap: wrap;}
  .label span{margin:0 10px 10px 0;}
  @media (max-width: 768px) {
     .form{display: grid;grid-template-columns:1fr;}
     .left{border-right: 0px solid #EDF2F7;padding-right:0;margin-right: 0;}
  }

</style>
<style>
  .yellow_color {font-weight: bold; color: #ffbd33 !important;}
  .green_color {font-weight: bold; color: #ff0023 !important;}
</style>
