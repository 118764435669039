<template>
  <div class="content" v-loading="loading">
    <div class="form">
      <div class="login_name">
          <img src="../assets/imgs/icons/vector.png" style="width: auto;cursor: pointer;" @click="pageIndex = 0" v-if="pageIndex == 1 || pageIndex == 2"/>
          <img :src="obj.company_logo" v-if="obj.company_logo"/>
          {{obj.company_name}}
      </div>

      <div class="info" v-if="pageIndex == 0">
        <h1 class="h1">{{obj.company_name}}</h1>
        <!-- <p class="p">How likely are you to recommend us to a friend or colleage?</p>
        <div class="flex">
          <span @click="change_frient_refer(false)" :class="friend_refer?'btn gray':'btn'">No</span>
          <span @click="change_frient_refer(true)" :class="friend_refer?'btn':'btn gray'">Yes!</span>
        </div> -->
        <p class="p">Select your rating</p>
        <el-rate v-model="rate" :allow-half="false" class="custom-rate"></el-rate>
        <label class="flex check">
          <input type="checkbox" v-model="marketing_permission_allow"/>
          Check to subscribe our newsletter
        </label>
        <div class="btn" @click="next_save">
          Next
        </div>
      </div>
      <div class="info" v-if="pageIndex == 1">
        <p class="p">Please leave us feedback</p>
        <div class="edit">
          <el-input
            type="textarea"
            :autosize="{ minRows: 16, maxRows: 16}"
            placeholder="Please leave us feedback"
            v-model="textarea_text"
            resize="none"
            style="width: 500px;height: 350px;">
          </el-input>
        </div>
        <div class="btn" @click="submit_feedback">
          Submit
        </div>
      </div>
      <div class="info" v-if="pageIndex == 2">
        <p class="p font-size-26">Please leave us your review on</p>
        <div class="flex imgs">

          <div class="review_button" @click="click_url(1, obj.google_url)">
            <img class="url_icon" src="../assets/imgs/icons/icon_pro6.png" v-if="obj.google_url" />
            Google
          </div>
          <div class="review_button margin-left" @click="click_url(2, obj.yelp_url)">
            <img class="url_icon" src="../assets/imgs/icons/icon_pro5.png" v-if="obj.yelp_url"/>
            Yelp
          </div>
        </div>
        <!-- <div class="btn" @click="pageIndex = 3">
          Complete
        </div> -->
      </div>
      <div v-if="pageIndex == 3">
        <el-result icon="success" title="Review success" subTitle="Thank you for participation">
          <template slot="extra">
          </template>
        </el-result>
      </div>
      <div v-if="pageIndex == 4">
        <el-result icon="success" title="Thank you for participating" subTitle="You’ve already finished providing your rating.">
          <template slot="extra">
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { getActionApi,patchActionApi } from '@/network/manageApi';
  import Vue from 'vue'


  export default {
    name: 'BusinessPromoterDetail',
    components: {
    },
    data() {
      return {
        loading: false,
        key: '',
        pageIndex: 0,
        friend_refer: true,
        obj: {},
        rate: 0,
        marketing_permission_allow: true,
        textarea_text: ""
      }
    },
    computed: {

    },
    created() {
      this.initPage()
    },
    methods: {
      click_url(type, url) {
        let param = {}
        if(type == 1) {
          param.google_review_clicked = true
        } else {
          param.yelp_review_clicked = true
        }
        patchActionApi("/review/business-promoter/clicked/" + this.key, param).then(res => {
          if(res.code == 200) {
            window.open(url)
          }
        })
      },
      next_save() {
        if(this.rate == 0) {
          this.$message({
            message: 'Please give us a score.',
            type: 'warning'
          });
          return;
        }
        let param = {marketing_permission_allow: this.marketing_permission_allow,friend_refer: this.friend_refer, rating:this.rate}
        patchActionApi("/review/business-promoter/clicked/" + this.key, param).then(res => {
          if(res.code == 200) {
            if(this.rate <= 3) {
              this.pageIndex = 1
            } else {
              this.pageIndex = 2
            }
          }
        })
      },
      submit_feedback() {
        if(this.textarea_text == "") {
          this.$message({
            message: 'Please leave your feedback information.',
            type: 'warning'
          });
          return;
        }
        let param = {bad_rating_feedback: this.textarea_text}
        patchActionApi("/review/business-promoter/clicked/" + this.key, param).then(res => {
          if(res.code == 200) {
            this.pageIndex = 3
          }
        })
      },
      change_frient_refer(flag) {
        this.friend_refer = flag
      },
      async initPage() {
        this.key = this.$route.params.key
        try {
          this.loading = true
          let res = await getActionApi("/review/business-promoter/clicked/" + this.key)
          if(res.data.previous_checked.rating == null) {
            this.pageIndex = 0
          } else if(res.data.previous_checked.rating > 3 && (!res.data.previous_checked.google_review_clicked || !res.data.previous_checked.yelp_review_clicked)) {
            this.pageIndex = 2
          } else if(res.data.previous_checked.rating > 3 && res.data.previous_checked.google_review_clicked && res.data.previous_checked.yelp_review_clicked) {
            this.pageIndex = 4
          } else {
            this.pageIndex = 1
          }
          this.obj = res.data
        } catch(e) {
            this.$router.push("/404");
        }
        this.loading = false
      }
    }
  }
</script>
<style>
  .custom-rate .el-rate__icon{
    font-size: 30px;
  }
</style>
<style scoped>
  .content{width: 100%;height: 100vh;display: flex;justify-content: center;align-items: center;}
  .form{background-color: #ffffff;border-radius: 4px;width: 100%;max-width:800px;box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.02);padding:20px 20px 70px 20px;display: flex;align-items: center;flex-direction: column;}
  .form .login_name img{height:30px;width:30px;margin-right: 15px;}
  .form .btn{width: 100px;height: 46px;background-color: #5856D6;color: #ffffff;cursor: pointer;display: flex;align-items: center;justify-content: center;border-radius: 4px;margin:20px 10px;}
  .form .flex{display: flex;justify-content: center;align-items: center;}
  .form .gray{background-color: #dddddd;color:#999999;}
  .form .p{color:#5856D6;margin:20px 0;}
  .form .h1{font-size: 16px;}
  .form .check{color:#5856D6;cursor: pointer;margin-top:40px;font-size: 16px;}
  .form .check input{width:20px;height: 20px;margin-right: 10px;}
  .form .info{display: flex;flex-direction: column;align-items: center;}
  .edit{width: 100%;margin:20px 0;}
  .imgs {margin-top: 50px;}
  .imgs img{margin:30px;}
  @media (max-width: 768px) {
    .form{width: 100%;min-height: 100vh;}
  }
  .margin-left {margin-left: 50px;}
  .login_name {display: flex;align-items: center;font-size: 18px;font-weight: bold;color: #0c304f;justify-content: flex-start;width:100%;margin-bottom: 30px;}
  .url_icon {height: 100px !important;width:100px !important;}
  .font-size-26 {font-size: 26px;}
  .review_button:hover {box-shadow: 0px 2px 4px 0px #0000001A;}
  .review_button {display: flex;flex-direction:column;align-items: center;color: #5856D6;font-size: 20px;font-weight: bold;border:1px solid #EDF2F7;padding: 0px 15px 30px 15px;border-radius: 16px;box-shadow: 0px 1px 2px 0px #0000000F;cursor: pointer;}
</style>
