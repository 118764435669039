<template>
  <el-dialog title="Send Request" :width="dialogWidth" :visible.sync="visible" :close-on-click-modal="false">
    <div v-loading="form_loading">
      <div class="form">
        <div class="div">
          Name*
          <el-input class="margin-top-switch" placeholder="Name" v-model="account_obj.name"/>
        </div>
        <div class="div">
          <div>
            <el-checkbox v-model="is_need_email">E-Mail</el-checkbox>
          </div>
          <el-input v-if="is_need_email" class="margin-top-switch" placeholder="E-Mail" v-model="account_obj.email"/>
        </div>
        <div class="div">
          <div>
            <el-checkbox v-model="is_need_sms">Phone Number</el-checkbox>
          </div>
          <el-input v-if="is_need_sms" class="margin-top-switch" placeholder="Phone Number" v-model="account_obj.phone_number" @change="change_phone">
            <template slot="prepend">+1</template>
          </el-input>
        </div>

      </div>
      <div class="dialog-footer" style="display: flex;justify-content: flex-end;">
        <el-button type="primary" size="small" @click="save_send" style="background-color: #5856D6;border-color:#5856D6;">Send</el-button>
      </div>
    </div>
  </el-dialog>

</template>

<script>
import Vue from 'vue';
import { getActionApi, postActionApi } from '@/network/manageApi';
export default {
  name: 'Request',
  components: {},
  data() {
    return {
      dialogWidth:'800px',
      visible: false,
      account_obj: {},
      form_loading: false,
      is_need_email: false,
      is_need_sms:false
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    change_phone() {
      if(!this.account_obj.phone_number) {
        return;
      }
      let phone = this.account_obj.phone_number.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replace(/[^0-9]/g, '')
      if(phone.length != 10) {
        this.$message({
          message: 'Please enter the correct phone number.',
          type: 'warning'
        });
        this.account_obj.phone_number = ""
        return;
      }
      phone = "+1" + phone
      this.account_obj.phone_number = "(" + phone.substring(2,5) + ") " + phone.substring(5,8) + " - " + phone.substring(8);
    },
    init() {
      this.visible = true
      this.account_obj = {}
      this.is_need_email = false
      this.is_need_sms = false
    },
    save_send() {
      if(!this.account_obj.name) {
        this.$message({
          message: 'Please enter your name.',
          type: 'warning'
        });
        return;
      }
      let param = {name: this.account_obj.name}
      if(this.is_need_email) {
        if(!this.account_obj.email) {
          this.$message({
            message: 'Please enter your e-mail.',
            type: 'warning'
          });
          return;
        }
        if(!this.validateEmail(this.account_obj.email)) {
          this.$message({
            message: 'Please enter the correct e-mail.',
            type: 'warning'
          });
          return;
        }
        param.email = this.account_obj.email
      }
      if(this.is_need_sms) {
        if(!this.account_obj.phone_number) {
          this.$message({
            message: 'Please enter your phone number.',
            type: 'warning'
          });
          return;
        }
        // if(!this.validatePhoneNumber(this.account_obj.phone_number)) {
        //   this.$message({
        //     message: 'Please enter the correct phone number.',
        //     type: 'warning'
        //   });
        //   return;
        // }
        param.phone_number = "+1" + this.account_obj.phone_number.replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")
      }
      this.form_loading = true
      postActionApi("/review/business-promoter", param).then((res) => {
        if(res.code == 201 || res.code == 200) {
          this.$emit('ok')
          this.visible = false
        }
      }).catch((err) => {
        this.$message.error({message: err.response.data.details.data.message});
      }).finally((res) => {
        this.form_loading = false
      })
    },
    validateEmail(email) {
        var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    },
    validatePhoneNumber(phoneNumber) {
      var regex = /^(\+\d+-)?\d+-\d+-\d+$/;
      return regex.test(phoneNumber);
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 800 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  .form{display: flex;flex-direction: column;}
  .form .flex{display: grid;grid-template-columns:1fr 1fr;gap: 20px;padding-bottom: 0;}
  .form .div{display: flex;flex-direction: column;color:#A0ABC0;font-weight: bold;padding-bottom:20px;}
  .form .div input{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;}
  .form .div select{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;box-sizing: border-box;}
  .headImage{display: flex;width: 76px;margin-top:15px;}
  .headImage .edit{display: none;padding-bottom:0;width: 76px;height: 76px;position: absolute;justify-content: center;align-items: center;background-color: rgba(0, 0, 0, 0.5);border-radius: 4px;}
  .headImage .edit span{background-color: rgba(255, 255, 255, 0.36);margin-left:0;padding:4px 10px;border-radius:4px;font-weight: bold;color:#ffffff;cursor: pointer;}
  .headImage:hover .edit{display: flex;}
  .headImage img{width: 76px;height: 76px;border-radius: 4px;}
  .default_upload {width: 76px;height: 76px;border: 1px dashed #d9d9d9;border-radius: 6px;cursor: pointer;display: flex;align-items: center;justify-content: center;}
  .avatar-uploader-icon {font-size: 18px;color: #8c939d;}
  .font-size-16 {font-size: 16px;}
  .margin-top-switch {margin-top: 10px;}
</style>
