<template>
  <div v-loading="loading">
    <div class="type">
      <div>
        <span :class="type == 0 ? 'act' : ''" @click="type = 0">Chart</span>
        <span :class="type == 1 ? 'act' : ''" @click="type = 1">Map</span>
      </div>
    </div>
    <div class="home" v-show="type == 0">
      <div class="seo">
        <div class="title">
          SEO Anaytics Report
          <div class="time" v-if="!(year == 0 && month == 0)">
            <div class="download" v-if="seoMain.seo_report_uuid != null" v-loading="downloadLoading" @click="download">Download<i class="el-icon-download"></i></div>
            <span @click="changeInit(-1)"><i class="el-icon-arrow-left"></i></span>
            {{getMonStr(this.month)}} {{this.year}}
            <span @click="changeInit(1)"><i class="el-icon-arrow-right"></i></span>
          </div>
          <div v-else>
            <span style="color: #dddddd;">No data</span>
          </div>
        </div>
        <div class="img">
          <img :src="getImg(seoMain.public_files_uuid)">
          <div class="div">
            <p>Displaying SEO Analytics Report for:</p>
            <p>{{getMonStr(seoMain.report_month)}} {{seoMain.report_year}}</p>
            <b>{{seoMain.company_name}}</b>
            <p>{{seoMain.address1}}, {{seoMain.address2}}, {{seoMain.state}} {{seoMain.zip_code}}, {{seoMain.city}}</p>
            <a :href="seoMain.seo_website_url" target="_blank">{{seoMain.seo_website_url}}</a>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <i class="el-icon-user"></i>
            <div>
              Unique Visitor
              <b>{{seoMain.main?seoMain.main.unique_visitor:0}}</b>
            </div>
          </div>
          <div class="item">
            <i class="el-icon-view"></i>
            <div>
              Page Views
              <b>{{seoMain.main?seoMain.main.page_views:0}}</b>
            </div>
          </div>
          <div class="item">
            <i class="el-icon-upload2"></i>
            <div>
              Average Engagement Rate
              <b>{{seoMain.main?seoMain.main.average_engagement_rate:0}}</b>
            </div>
          </div>
          <div class="item">
            <i class="el-icon-star-off"></i>
            <div>
              Average Session Duration
              <b>{{seoMain.main?seoMain.main.average_session_duration:0}}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="charts">
        <div class="item">
          <div id="chart1"></div>
        </div>
        <div class="item">
          <div id="chart2"></div>
        </div>
      </div>
      <div class="tables">
        <div class="item">
          <h1>Engagement</h1>
          <div class="table" v-if="traffic_acquisition != null">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Bounce Rate</span>
              <span>{{traffic_acquisition.bounce_rate?traffic_acquisition.bounce_rate:0}}<a>{{traffic_acquisition.bounce_rate}} %</a></span>
            </div>
            <div class="row">
              <span>Engagement Rate</span>
              <span>{{traffic_acquisition.engagement_rate?traffic_acquisition.engagement_rate:0}}<a>{{traffic_acquisition.engagement_rate}} %</a></span>
            </div>
            <div class="row">
              <span> </span>
              <span> </span>
            </div>
          </div>
          <p v-if="traffic_acquisition != null">{{traffic_acquisition.description}}</p>
        </div>
        <div class="item">
          <h1>Device Category</h1>
          <div class="table" v-if="device_category != null">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Desktop</span>
              <span>{{device_category.desktop?device_category.desktop:0}} <a>{{formatNumber(device_category.desktop*100/(device_category.desktop+device_category.mobile+device_category.tablet))}}%</a></span>
            </div>
            <div class="row">
              <span>Mobile</span>
              <span>{{device_category.mobile?device_category.mobile:0}} <a>{{formatNumber(device_category.mobile*100/(device_category.desktop+device_category.mobile+device_category.tablet))}}%</a></span>
            </div>
            <div class="row">
              <span>Tablet</span>
              <span>{{device_category.tablet?device_category.tablet:0}} <a>{{formatNumber(device_category.tablet*100/(device_category.desktop+device_category.mobile+device_category.tablet))}}%</a></span>
            </div>
          </div>
          <p v-if="device_category != null">{{device_category.description}}</p>
        </div>
      </div>
      <div class="charts">
        <div class="item">
          <div id="chart3"></div>
        </div>
        <div class="item">
          <div id="chart4"></div>
        </div>
      </div>
      <div class="tables">
        <div class="item">
          <h1>Source Channels</h1>
          <div class="table" v-if="source_channels != null">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>Organic Search</span>
              <span>{{source_channels.organic_search?source_channels.organic_search:0}} <a>{{formatNumber(source_channels.organic_search*100/(source_channels.organic_search+source_channels.direct+source_channels.organic_social+source_channels.referral+source_channels.unassigned))}}%</a></span>
            </div>
            <div class="row">
              <span>Direct</span>
              <span>{{source_channels.direct?source_channels.direct:0}} <a>{{formatNumber(source_channels.direct*100/(source_channels.organic_search+source_channels.direct+source_channels.organic_social+source_channels.referral+source_channels.unassigned))}}%</a></span>
            </div>
            <div class="row">
              <span>Organic Social</span>
              <span>{{source_channels.organic_social?source_channels.organic_social:0}} <a>{{formatNumber(source_channels.organic_social*100/(source_channels.organic_search+source_channels.direct+source_channels.organic_social+source_channels.referral+source_channels.unassigned))}}%</a></span>
            </div>
            <div class="row">
              <span>Referral</span>
              <span>{{source_channels.referral?source_channels.referral:0}} <a>{{formatNumber(source_channels.referral*100/(source_channels.organic_search+source_channels.direct+source_channels.organic_social+source_channels.referral+source_channels.unassigned))}}%</a></span>
            </div>
            <div class="row">
              <span>Unassigned</span>
              <span>{{source_channels.unassigned?source_channels.unassigned:0}} <a>{{formatNumber(source_channels.unassigned*100/(source_channels.organic_search+source_channels.direct+source_channels.organic_social+source_channels.referral+source_channels.unassigned))}}%</a></span>
            </div>
          </div>
        </div>
        <div class="item">
          <h1>Top Pages</h1>
          <div class="table" v-if="landingPageList.length > 0">
            <div class="header">
              <span>Page</span>
              <span>Sessions</span>
            </div>
            <div class="row" v-for="item,index in landingPageList" :key="index">
              <span>{{item.page_name}}</span>
              <span>{{item.sessions}} <a>{{item.rate}}%</a></span>
            </div>
          </div>
        </div>
      </div>
      <div class="charts">
        <div class="item">
          <div id="chart5"></div>
        </div>
        <div class="item">
          <div id="chart6"></div>
        </div>
      </div>

      <div class="tables">
        <div class="item">
          <h1>Call / Website Clicks</h1>
          <div class="table" v-if="website_clicks != null">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row">
              <span>call_click_sessions</span>
              <span>{{website_clicks.call_click_sessions}} <a>{{formatNumber(website_clicks.call_click_sessions * 100 / (website_clicks.call_click_sessions+website_clicks.website_clicks_sessions))}} %</a></span>
            </div>
            <div class="row">
              <span>website_clicks_sessions</span>
              <span>{{website_clicks.website_clicks_sessions}} <a>{{formatNumber(website_clicks.website_clicks_sessions * 100 / (website_clicks.call_click_sessions+website_clicks.website_clicks_sessions))}} %</a></span>
            </div>
          </div>
          <p v-if="website_clicks != null">{{website_clicks.description}}</p>
        </div>
        <div class="item">
          <h1>New v/s Returing Users</h1>
          <div class="table" v-if="returningUsersList.length > 0">
            <div class="header">
              <span>Source</span>
              <span>Sessions</span>
            </div>
            <div class="row" v-for="(item,index) in returningUsersList" :key="index">
              <span>{{item.users_category}}</span>
              <span>{{item.sessions}} <a>{{item.rate}}%</a></span>
            </div>
          </div>
          <p v-if="returningUsersList.length > 0">{{returning_users.description}}</p>
        </div>
      </div>
    </div>
    <div class="home" v-show="type == 1">
      <div class="seo">
        <div class="title">
          SEO Places
          <div class="time">
            <div class="download" v-if="seoMain.seo_report_uuid != null" v-loading="downloadLoading" @click="download">Download<i class="el-icon-download"></i></div>
            <span @click="changeInit(-1)"><i class="el-icon-arrow-left"></i></span>
            {{getMonStr(this.month)}} {{this.year}}
            <span @click="changeInit(1)"><i class="el-icon-arrow-right"></i></span>
          </div>
        </div>
        <div class="img" v-for="item,index in gooleMap" :key="index">
          <div>
            <h4>Keyword :<a :href="'https://www.google.com/search?q=' + item.keyword" target="_blank">{{item.keyword}}</a></h4>
            <h4>Previous Month :<p>{{item.previous_month}}</p></h4>
            <h4>This Month :<p>{{item.this_month}}</p></h4>
          </div>
          <img class="map" :src="item.imgPath"/>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import * as echarts from 'echarts'
  import { getActionApi } from '@/network/manageApi'
  import axios from "@/utils/requestApi"
  import moment from 'moment/moment'
  export default {
    name: 'Seo',
    components: {

    },
    data() {
      return {
        type:0,
        downloadLoading:false,
        loading:false,
        year:0,
        month:0,
        seoMain:{},
        traffic_acquisition:{},
        device_category:{},
        source_channels:{},
        landing_page:{},
        landingPageList:[],
        website_clicks:{},
        returning_users:{},
        returningUsersList:[],
        gooleMap:[]
      }
    },
    computed: {

    },
    mounted(){
      getActionApi('/seo/latest-report').then((res) => {
        if(res.data.latest_year && res.data.latest_month){
          this.year = res.data.latest_year
          this.month = res.data.latest_month
          this.queryInfo()
        }
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message)
      })

    },
    created(){

    },
    methods:{
      changeInit(ind){
        let nowDate = moment(this.year + '-' + this.month).add(ind,'months')
        this.year = nowDate.format('YYYY')
        this.month = nowDate.format('MM')
        this.queryInfo()
      },
      getMonStr(mon){
        const monStr = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        return monStr[mon-1]
      },
      getImg(url){
        return `${process.env.VUE_APP_BASE_API}/public-file/public/`+url
      },
      getGoogleMap(id,index){
        axios.get(`${process.env.VUE_APP_BASE_API}/seo/detail/google-place/`+id, {responseType: 'blob'}).then((res) => {
          const blob = new Blob([res], { type: "image/*" })
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = () => {
            this.$set(this.gooleMap[index],'imgPath',reader.result)
          }
        })
      },
      queryInfo(){
        const params = {
          year:this.year,
          month:this.month
        }
        this.gooleMap = []
        this.landingPageList = []
        this.returningUsersList = []
        this.loading = true
        this.$echarts.dispose(document.getElementById('chart1'))
        this.$echarts.dispose(document.getElementById('chart2'))
        this.$echarts.dispose(document.getElementById('chart3'))
        this.$echarts.dispose(document.getElementById('chart4'))
        this.$echarts.dispose(document.getElementById('chart5'))
        this.$echarts.dispose(document.getElementById('chart6'))
        getActionApi('/seo/detail',params).then((res) => {
          this.seoMain = res.data
          this.traffic_acquisition = res.data.traffic_acquisition
          if(this.traffic_acquisition)
            this.initTrafficAcquisition()
          this.device_category = res.data.device_category
          if(this.device_category)
            this.initDeviceCategory()
          this.source_channels = res.data.source_channels
          if(this.source_channels)
            this.initSourceChannels()
          this.landing_page = res.data.landing_page
          if(this.landing_page)
            this.initLandingPage()
          this.website_clicks = res.data.website_clicks
          if(this.website_clicks)
            this.initWebsiteClicks()
          this.returning_users = res.data.returning_users
          if(this.returning_users && this.returning_users.data)
            this.initReturningUsers()
          getActionApi('/seo/detail/google-place',params).then((res) => {
            if(res.data && res.data.google_place){
              this.gooleMap = res.data.google_place
              for(let i = 0; i < this.gooleMap.length; i++){
                this.getGoogleMap(this.gooleMap[i].seo_report_google_places_uuid,i)
              }
            }
          }).catch((err) => {
            this.$message.error(err.response.data.details.data.message)
          }).finally((res) => {
            this.loading = false
          });
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message)
          this.loading = false
        })
      },
      download(){
        if(this.downloadLoading)
          return
        const that = this
        that.downloadLoading = true
        axios.get(`${process.env.VUE_APP_BASE_API}/seo/detail/`+this.seoMain.seo_report_uuid+'/download', {responseType: 'blob'}).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download','report.pdf')
          document.body.appendChild(link)
          link.click()
          that.downloadLoading = false
        })
      },
      initTrafficAcquisition(){
        var chartDom = document.getElementById('chart1');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#8886FF',
            '#68D391',
          ],
          tooltip: {
            trigger: 'item',
            axisPointer: {
              type: 'cross'
            },
            formatter:function(params){
              return `${params.data.name}: ${params.data.value}%`
            }
          },
          title: {
            text: 'Traffic Acquistion',
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            bottom:'2%'
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: [
                { value: this.traffic_acquisition.bounce_rate, name: 'Bounce Rate' },
                { value: this.traffic_acquisition.engagement_rate, name: 'Engagement Rate' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      initDeviceCategory(){
        var chartDom = document.getElementById('chart2');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#8886FF',
            '#68D391',
            '#F687B3'
          ],
          tooltip: {
            trigger: 'item'
          },
          title: {
            text: 'Device Category',
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            bottom:'2%',
            type: 'scroll',
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: [
                { value: this.device_category.desktop, name: 'Desktop' },
                { value: this.device_category.mobile, name: 'Mobile' },
                { value: this.device_category.tablet, name: 'Tablet' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      initSourceChannels(){
        var chartDom = document.getElementById('chart3');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#8886FF',
            '#68D391',
            '#F687B3',
            '#76E4F7',
            '#dddddd'
          ],
          tooltip: {
            trigger: 'item'
          },
          title: {
            text: 'Source Channels',
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            bottom:'2%',
            type: 'scroll',
          },
          series: [
            {
              type: 'pie',
              radius: '50%',
              data: [
                { value: this.source_channels.organic_search, name: 'Organic Search' },
                { value: this.source_channels.direct, name: 'Direct' },
                { value: this.source_channels.organic_social, name: 'Organic Social' },
                { value: this.source_channels.referral, name: 'Referral' },
                { value: this.source_channels.unassigned, name: 'Unassigned' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      initLandingPage(){
        var chartDom = document.getElementById('chart4');
        var myChart = this.$echarts.init(chartDom);
        let keyArray = []
        let dateArray = []
        let pageList = []
        let count = 0
        for (let [key, value] of Object.entries(this.landing_page)) {
          for(let valueItem of value){
            let hasKey = false
            count += valueItem.session_count
            for(let keyStr of keyArray){
              if(keyStr == valueItem.page_name)
                hasKey = true
            }
            if(!hasKey){
              pageList.push({page_name:valueItem.page_name,sessions:0,rate:0})
              keyArray.push(valueItem.page_name)
            }
          }
          dateArray.push(key)
        }
        let series = []
        for(let key of keyArray){
          let item = {
            name: key,
            type: 'line'
          }
          let datas = []
          for(let data of dateArray){
            datas.push(0)
            for (let [keyList, valueList] of Object.entries(this.landing_page)) {
              for(let valueItem of valueList){
                if(data == keyList && key == valueItem.page_name){
                  datas[datas.length-1] = valueItem.session_count
                  for(let valueList of pageList){
                    if(valueList.page_name == key)
                      valueList.sessions += valueItem.session_count
                  }
                }
              }
            }
          }
          item.data = datas
          series.push(item)
        }
        for(let valueList of pageList){
          valueList.rate = (valueList.sessions * 100 / count).toFixed(2)
        }
        pageList.sort((a, b) => b.sessions - a.sessions);
        this.landingPageList = pageList
        series.push({
          type: 'line',
          name:'Targeted',
          markLine: {
            symbol: 'none',
            data: [
              {
                yAxis: 20,
                label: {
                  show: false,
                },
                lineStyle: {
                  type: 'solid',
                  width: 1,
                  color: '#F56565',
                },
              }
            ],
          }
        })
        var option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          title: {
            text: 'Landing Page',
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            data:keyArray,
            type: 'scroll',
            bottom:'2%'
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '15%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: dateArray
            }
          ],
          yAxis: [
            {
              max: function (value) {
                return value.max < 50 ? 50 : value.max;
              },
              type: 'value'
            }
          ],
          series: series
        };
        myChart.setOption(option);
      },
      initWebsiteClicks(){
        var chartDom = document.getElementById('chart5');
        var myChart = this.$echarts.init(chartDom);
        var option = {
          color: [
            '#8886FF',
            '#68D391',
            '#F687B3',
            '#76E4F7'
          ],
          tooltip: {
            trigger: 'item'
          },
          title: {
            text: 'Direct / Discovery',
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            bottom:'2%',
            type: 'scroll',
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '10%',
            bottom: '10%',
            containLabel: true
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              data: [
                { value: this.website_clicks.call_click_sessions, name: 'Call ClickSessions' },
                { value: this.website_clicks.website_clicks_sessions, name: 'Website Clicks Sessions' }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      initReturningUsers(){
        var chartDom = document.getElementById('chart6');
        var myChart = this.$echarts.init(chartDom);
        let keyArray = []
        let dateArray = []
        let pageList = []
        let count = 0
        for (let [key, value] of Object.entries(this.returning_users.data)) {
          for(let valueItem of value){
            let hasKey = false
            count += valueItem.session_count
            for(let keyStr of keyArray){
              if(keyStr == valueItem.users_category)
                hasKey = true
            }
            if(!hasKey){
              pageList.push({users_category:valueItem.users_category,sessions:0,rate:0})
              keyArray.push(valueItem.users_category)
            }
          }
          dateArray.push(key)
        }
        let series = []
        for(let key of keyArray){
          let item = {
            name: key,
            type: 'line'
          }
          let datas = []
          for(let data of dateArray){
            datas.push(0)
            for (let [keyList, valueList] of Object.entries(this.returning_users.data)) {
              for(let valueItem of valueList){
                if(data == keyList && key == valueItem.users_category){
                  datas[datas.length-1] = valueItem.session_count
                  for(let valueList of pageList){
                    if(valueList.users_category == key)
                      valueList.sessions += valueItem.session_count
                  }
                }
              }
            }
          }
          item.data = datas
          series.push(item)
        }
        for(let valueList of pageList){
          valueList.rate = (valueList.sessions * 100 / count).toFixed(2)
        }
        pageList.sort((a, b) => b.sessions - a.sessions);
        this.returningUsersList = pageList
        series.push({
          type: 'line',
          name:'Targeted',
          markLine: {
            symbol: 'none',
            data: [
              {
                yAxis: 20,
                label: {
                  show: false,
                },
                lineStyle: {
                  type: 'solid',
                  width: 1,
                  color: '#F56565',
                },
              }
            ],
          }
        })
        var option = {
          color: [
            '#5856D6',
            '#68D391',
            '#F56565'
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          title: {
            text: 'New v/s Returing Users',
            textStyle:{
              color:'#2D3648',
              fontSize:20
            },
            left: 'center'
          },
          legend: {
            data:keyArray,
            bottom:'2%',
            type: 'scroll',
          },
          grid: {
            left: '5%',
            right: '5%',
            bottom: '15%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: dateArray
            }
          ],
          yAxis: [
            {
              max: function (value) {
                return value.max < 50 ? 50 : value.max;
              },
              type: 'value'
            }
          ],
          series: series
        };
        myChart.setOption(option);
      },
      formatNumber(num){
        return isNaN(num) ? 0.00 : num.toFixed(2)
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .type{display: flex;justify-content: center;margin-top:20px;}
  .type div{display: flex;align-items: center;justify-content: center;background-color: rgba(0, 0, 0, 0.08);border-radius: 8px;}
  .type div span{line-height: 50px;width: 160px;text-align: center;font-size:20px;color:#21272A;cursor: pointer;}
  .type div .act{color:#5856D6;background-color: #ffffff;border-radius: 8px;box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);}
  .seo{display: flex;flex-direction: column;}
  .seo .img{background-color: #ffffff;margin-bottom:14px;padding:20px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;}
  .seo .img img{max-height:60px;}
  .seo .img .div{border-top:1px solid #A0AEC0;margin-top:10px;padding-top:10px;display: flex;flex-direction: column;align-items: flex-start;}
  .seo .img p{font-size: 12px;color:#4A5468;line-height: 2;font-weight: normal;}
  .seo .img h4{font-size: 12px;color:#4A5468;line-height: 2;font-weight: bold;display: flex;align-items: center;}
  .seo .img h4 p{margin-left:10px;}
  .seo .img .map{margin-top:10px;width:900px;max-height: 100000px;}
  .seo .img b{color:#4A5468;font-size:16px;padding:10px 0;}
  .seo .img a{color:#4299E1;font-size:12px;text-decoration: underline;cursor: pointer;}
  .seo .list{display: grid;gap: 14px;grid-template-columns: 1fr 1fr 1fr 1fr;}
  .seo .list .item{height:80px;background-color: #ffffff;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;padding:0 30px;display: flex;align-items: center;}
  .seo .list .item i{color:#5856D6;font-size:30px;margin-right: 20px;}
  .seo .list .item div{display: flex;flex-direction: column;font-size: 12px;color:#A0AEC0;}
  .seo .list .item div b{font-size: 20px;color: #2D3748;}
  .charts{display: grid;gap: 20px;grid-template-columns: 1fr 1fr;}
  .charts .item{background-color: #ffffff;padding:20px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;}
  h1{font-size: 20px;color: #2D3648;text-align: center;}
  .charts .item div{height:300px;}
  .title{display: flex;justify-content: space-between;align-items: center;height:50px;font-size:20px;color:#2D3648;font-weight: bold;padding:0 10px;}
  .time{display: flex;align-items: center;color:#5856D6;font-size: 14px;font-weight: bold;}
  .time span{border:1px solid #5856D6;height:20px;width:20px;border-radius: 5px;display: flex;justify-content: center;align-items: center;cursor: pointer;margin:0 14px;}
  .time span i{font-size:12px;font-weight: bold;}
  .tables{background-color: #ffffff;margin-bottom:14px;padding:20px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);border-radius: 16px;display: grid;gap: 20px;grid-template-columns: 1fr 1fr;}
  .tables .item{display: flex;flex-direction: column;}
  .tables .item h1{text-align: left;}
  .tables .item .table {border-top:1px solid #EDF2F7;border-bottom: 1px solid #EDF2F7;margin:20px 0;}
  .tables .item .table .header{background-color: #F8F9FA;height:40px;display: flex;align-items: center;color:#4A5468;font-size:14px;}
  .tables .item .table span{padding:0 20px;display: flex;align-items: center;justify-content: space-between;}
  .tables .item .table span:nth-child(1){width:100%;}
  .tables .item .table span:nth-child(2){width: 140px;flex-shrink: 0;}
  .tables .item .table .row{border-bottom:1px dashed #EDF2F7;height:40px;display: flex;align-items: center;color:#1A202C;font-size:14px;}
  .tables .item .table .row:last-child{border-bottom-width: 0;}
  .tables .item .table .row a{color:#48BB78;font-size:12px;margin-left:10px;}
  .tables .item .table .header span{font-weight: bold;}
  .tables .item .table .header span:last-child{justify-content: center;}
  .tables .item p{background-color: #F7F9FC;padding:10px 20px;line-height: 2;font-size: 14px;color:#1A202C;}
  .download{cursor: pointer;margin-right: 20px;display: flex;align-items: center;}
  .download i{font-weight: bold;margin-left: 5px;font-size: 20px;}
  @media (max-width: 768px) {
    .seo .list{gap: 14px;grid-template-columns: 1fr;}
    .tables{grid-template-columns: 1fr;}
    .charts{grid-template-columns: 1fr;}
    .seo .img .map{width:100%;}
    .download{font-size: 13px;}
    .title{flex-direction: column;margin-bottom:20px;font-size: 13px;}
    .title .time{margin-top:10px;font-size: 13px;}
    .tables .item .table span:nth-child(2){width: 100px;flex-shrink: 0;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .seo .list{height:174px;display: grid;gap: 14px;grid-template-columns: 1fr 1fr;}
    .seo .img .map{width:100%;}
  }
</style>
