<template>
  <div class="login">
    <div class="bg"></div>
    <div class="form" v-loading="loading">
      <div class="login_name">
          <img src="../assets/imgs/logo_group.png" v-if="show_type == 0"/>
          <img src="../assets/imgs/logo_dental.png" v-if="show_type == 1"/>
      </div>

      <h1>Welcome!</h1>
      <span>Enter your email and password to sign in</span>
      <div class="div" :class="actType == 1 ? 'act' : ''">
        <input placeholder="e-mail" @focus="actType = 1" @keyup.enter="loginFunction" @blur="actType = 0" v-model="user_email"/>
      </div>
      <div class="div" :class="actType == 2 ? 'act' : ''">
        <input :type="type" @focus="actType = 2" v-model="password" @keyup.enter="loginFunction" @blur="actType = 0" placeholder="Password"/>
        <img class="pass" v-if="type == 'password'" @click="changeType" src="../assets/imgs/un_visibility.png"/>
        <img class="pass" v-else @click="changeType" src="../assets/imgs/visibility.png"/>
      </div>
      <div class="rem">
        <label>
          <input class="input" v-model="remember" type="checkbox"/>
          Remember Me
        </label>
        <a @click="go('/findId')">Find password</a>
      </div>
      <div class="error" v-if="error != ''">{{error}}</div>
      <div class="btn" @click="loginFunction">Login</div>
      <div class="copy">Copyright © {{copyright_year}}  {{website_name}} All rights reserved</div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Vue from 'vue'
  import { ACCESS_TOKEN } from "@/store/mutation-types"

  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        type:'password',
        loading:false,
        error:'',
        user_email:'',
        password:'',
        actType:0,
        remember:false,
        copyright_year: null,
        website_name: "Ditans Group",
        show_type: 0
      }
    },
    computed: {

    },
    created() {
      this.copyright_year = new Date().getFullYear();
      Vue.ls.remove(ACCESS_TOKEN)
      this.initUsername();
      let domainName = window.location.hostname;
      if(domainName == "portal.lighthousedentalsolutions.com") {
        this.website_name = "Lightouse Dental"
        this.show_type = 1
      } else {
        this.show_type = 0
        this.website_name = "Ditans Group"
      }
    },
    methods: {
      ...mapActions([ "Login" ]),
      initUsername(){
        if(!!Vue.ls.get("admin_email")) {
            this.user_email = Vue.ls.get("admin_email")
            this.remember = true
        }
      },
      loginFunction(){
        const testStr = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        if(this.user_email == ''){
          this.error = 'Email is required';
        }else if(!testStr.test(this.user_email)){
          this.error = 'Email format incorrect'
        }else if(this.password == ''){
          this.error = 'Password is required';
        }else{
          const params = {
            user_email: this.user_email,
            password: this.password,
          }
          this.error = ''
          this.loading = true
          const that = this
          this.Login(params).then((res) => {
            that.loginSuccess()
          }).catch((err) => {
            this.error = err.response.data.details.data.message
          }).finally((res) => {
            this.loading = false
          });
        }
      },
      loginSuccess () {
        if(this.remember) {
          Vue.ls.set("admin_email", this.user_email, 30 * 24 * 60 * 60 * 1000)
        } else {
          Vue.ls.remove("admin_email")
        }
        if (this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
          this.$router.replace(this.$route.query.redirect).catch(err => {})
        } else {
          this.$router.replace('/').catch(err => {})
        }
      },
      changeType(){
        this.type = this.type == 'password' ? 'text' : 'password'
      },
      go(url){
        this.$router.push(url);
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .login{display: flex;justify-content: center;align-items: center;height:100vh;}
  .bg{position: fixed;top:20px;left:20px;z-index: -1;width:calc(100vw - 40px);border-radius: 10px;height:40vh;display: flex;background-image: url(../assets/imgs/login_bg.jpg);background-size: 100% 100%;}
  .form{background-color: #ffffff;width:404px;box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.1);border-radius: 16px;padding:32px 42px;display: flex;flex-direction: column;}
  .form img{width:180px;margin-right: 15px;}
  .form h1{color:#5856D6;font-size:28px;margin-top:32px;line-height: 1;}
  .form span{color:#A0AEC0;font-size:14px;margin-top:10px;margin-bottom:32px;}
  .form .div{border: 1px solid #CBD5E0;border-radius: 6px;overflow: hidden;margin-bottom:14px;padding:10px 14px;display: flex;align-items: center;}
  .form .act{border:1px solid #5856D6;}
  .form input{border:none;height:20px;font-size:14px;width: 100%;}
  .form .rem{display: flex;justify-content: space-between;align-items: center;font-size:12px;}
  .form .btn{height:52px;width:100%;background-color: #5856D6;cursor: pointer;color:#ffffff;font-size:17px;display: flex;align-items: center;justify-content: center;border-radius: 8px;margin:32px 0;}
  .form .copy{color:#A0AEC0;font-size:12px;align-self: center;}
  .form a{color:#A0AEC0;cursor: pointer;}
  .form label{display:flex;align-items: center;color:#718096;}
  .form label .input{width:20px;height:20px;border-radius: 4px;margin-right:10px;}
  .form .pass{width:20px;height:20px;cursor: pointer;}
  .error{color:#F56565;text-align: center;margin-top:10px;font-size: 14px;}
  @media (max-width: 768px) {
    .form{width:80vw;padding:20px 20px 40px 20px;border-radius: 10px;}
    .form h1{font-size:26px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
  }
  .login_name {display: flex;align-items: center;font-size: 18px;font-weight: bold;color: #0c304f;}
</style>
