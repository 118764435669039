import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { Notification, MessageBox } from 'element-ui'
import { ACCESS_TOKEN } from '@/store/mutation-types'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 3600000
})

const err = (error) => {
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    switch (error.response.status) {
      case 401:
        store.dispatch('Logout')
        MessageBox.confirm('Sorry, the login status is expired. Log in again', 'Tips', {
          confirmButtonText: 'Log in',
        }).then(() => {
          window.location.href='/login';
        })
        break
      default:
        break
    }
  }
  return Promise.reject(error)
}

service.interceptors.request.use(
  config => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
      config.headers['Authorization'] = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use((response) => {
  return response.data
}, err)

export default service
